.PageBase
{
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
a
{
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    color: black;
}
.noneArea
{
    display: none;
}

.MenuBase
{
    width: 17vh;
    height: 99.7vh;
    position: absolute;
    top: 0;
    left: 0.05vh;
    border: solid 0.2vh;
    z-index: 5;
    background-color: rgb(255,255,255);
}

.MenuLogo
{
    width: 16.8vh;
    border: solid 0.2vh;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
}

.space
{
    width: 17vh;
    height: 5vh;
    display: flex;
    top: 0;
    color: white;
    font-size: 2vh;
}

.MenuLanguage
{
    width: 10vh;
    height: 10vh;
    display: flex;
    margin-top: 2vh;
    margin-left: auto;
    margin-right: auto;
    border: solid 0.2vh;
    font-size: 2vh;
    font-weight: bold;
    align-items: center;
    justify-content: center;
}
.SmallMenu{font-size: 1.7vh;}
.Choice
{
    border: double 0.6vh;
}

.BackImgArea
{
    width: 100vw;
    height: 100vh;
    margin: 0;
    font-size: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}
.BackImg
{
    width: 170vh;
    min-width: 100vw;
    top: 0;
    left: 0;
}

.MenuReLoad
{
    width: auto;
    position: absolute;
    top: 0vh;
    right: 3vh;
    padding: 0;
    margin: 0;
    font-size:min(2vh,3vw);
    color: red;
}

.SelectArea
{
    width: 100vw;
    height: 96vh;
    display: block;
    position: absolute;
    top: 2vh;
    left: 0;
    overflow: hidden;
    overflow-y: scroll;
}

.SelectQ
{
    width: auto;
    height: auto;
    display: flex;
    margin-left: 33vh;
    margin-right: 10vh;
    justify-content: center;
}
.SQChAllArea
{
    height: 3vw;
    margin-top: 0vh;
    font-size: 0;
}
.SelectQArea
{
    width: 35vw;
    height: auto;
    display: flex;
    margin-top: 3vh;
    margin-left: 0;
    margin-right: 3vw;
    border-top: solid;
    align-items: center;
}
.SelectQIcon
{
    width: 5vw;
    display: flex;
    align-items: flex-start;
    margin-top: 2vh;
    margin-left: 1vw;
    align-items: center;
    justify-content: center;
}
.PlayBtn
{
    margin-top: 3vh;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    font-size: 4vh;
}
.PlayBtn2
{
    margin-top: 0vh;
    margin-left: 0vw;
    margin-right: 0.5vw;
    font-size: 3vh;
}
.SelectQTitle
{
    width: 10vw;
    display: flex;
    align-items: flex-start;
    margin-top: 3vh;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    font-size: 1.2vw;
}
.SelectQText
{
    width: 15vw;
    display: flex;
    align-items: flex-start;
    margin-top: 5vh;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    font-size: 1.2vw;
    border-top: solid;
    padding-top: 5vh;
}
.SelectQChText
{
    font-size: 1vw;
}
.SelectQChapter
{
    width: 10vw;
    height: 5vh;
    display: flex;
    align-items: flex-start;
    margin-top: 3vh;
    margin-left: 1vw;
    margin-right: 2vw;
    align-items: center;
    justify-content: center;
    font-size: 1.6vw;
    color: rgb(31,137,255);
    border: solid;
    border-radius: 2vw;
    background-color: white;
}
.SQChAll
{
    margin-top: 0vh;
    color: white;
    background-color: rgb(31,137,255);
}
.SQBottom{opacity: 0;}
.SQBAll{
    margin-top: 0vh;
    border: none;
}
.SQBClea
{
    display: flex;
}

.SelectPos
{
    width: auto;
    height: auto;
    display: flex;
    margin-left: 25vh;
    margin-right: 10vh;
}
.SelectQTextLog
{
    width: 37vh;
    max-width: 80vw;
    height: 7vh;
    position: relative;
    display: flex;
    top: 0vh;
    left: 50%;
    right: 50%;
    border: solid 0.2vh;
    align-items: center;
    justify-content: center;
    font-size: 2.5vh;
    background-color: white;
    transform: translateY(0%) translateX(-50%);
}
.SelectQTextJS
{
    width: auto;
    position: relative;
    display: flex;
    top: 0vh;
    left: 50%;
    right: 50%;
    color: red;
    align-items: center;
    justify-content: center;
    font-size: 2vh;
    transform: translateY(0%) translateX(-50%);
}
.SelectQTextJSLine{border-bottom: solid 0.2vh;}


.MoveArea
{
    width: auto;
    height: auto;
    display: flex;
    margin-top: 3vh;
    margin-bottom: auto;
    margin-left: 18vh;
    margin-right: auto;
}
.MoveChapArea
{
    display: block;
    top: 3vh;
    margin-right: 0;
    justify-content: center;
    z-index: 1;
}
.MoveChapArea1
{
    display: block;
    top: 3vh;
    margin-left: auto;
    margin-right: 0;
    justify-content: center;
    z-index: 1;
}
.MoveChapArea2
{
    display: none;
}
.MoveChapArea3
{
    display: none;
}
.MoveChapBtn
{
    height: 10vh;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    font-size: 2vh;
    border: solid 0.2vh;
    background-color: rgb(255,255,255);
}
.MChBChange{background-color: rgb(255,255,25); font-weight: bold;}
.MoveSet
{
    width: 125vh;
    max-width: 80vw;
    position: relative;
    margin-left: 0;
    margin-right: auto;
    border: solid 0.2vh;
    z-index: 2;
}

.TextArea
{
    width: auto;
    height: auto;
    display: flex;
    margin-top: 0.1vh;
    margin-bottom: auto;
    margin-left: 23vh;
    margin-right: auto;
}
.MoveText
{
    width: 125vh;
    max-width: 80vw;
    height: 20vh;
    display: block;
    margin-top: 0vh;
    margin-left: auto;
    margin-right: auto;
    border: solid 0.2vh;
    background-color: rgb(255,255,255);

    font-size: 7vh;
}
.BurmaMoveText{font-size: 5vh;}



@media screen and (orientation: portrait)
{
    .MenuClose
    {
        height: 9vh;
        overflow: hidden;
    }

    .space
    {
        width: 17vh;
        height: 5vh;
        display: flex;
        top: 0;
        color: black;
        align-items: center;
        justify-content: center;
    }

    .BackImgArea
    {
        width: 100vw;
        height: 100vh;
        margin: 0;
        font-size: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }
    .BackImg
    {
        width: 170vh;
        position: relative;
        top: 0;
        left: 0;
        margin-left: 50%;
        margin-right: 50%;
        transform: translateY(0%) translateX(-50%); 
    }

    .SelectQ
    {
        width: 100vw;
        display: block;
        margin-left: 5vw;
        margin-right: 0vh;
        justify-content: center;
    }
    .SelectQArea
    {
        width: 90vw;
        display: flex;
        margin-top: 5vw;
        margin-left: 0;
        margin-right: 3vw;
        border-top: solid;
        align-items: center;
    }
    .SelectQIcon
    {
        width: 15vw;
        display: flex;
        align-items: flex-start;
        margin-top: 2vh;
        margin-left: 1vw;
        align-items: center;
        justify-content: center;
    }
    .PlayBtn
    {
        margin-top: 3vw;
        margin-left: 0vw;
        margin-right: 0.5vw;
        font-size: 10vw;
    }
    .PlayBtn2
    {
        margin-top: 0vh;
        margin-left: 0vw;
        margin-right: 0.5vw;
        font-size: 6vw;
    }
    .SelectQTitle
    {
        width: 30vw;
        font-size: 4vw;
    }
    .SelectQText
    {
        width: 35vw;
        display: flex;
        align-items: flex-start;
        margin-top: 0vh;
        margin-left: 0;
        margin-right: 0;
        align-items: center;
        font-size: 3vw;
    }
    .SelectQChText
    {
        font-size: 2.5vw;
    }
    .SelectQChapter
    {
        width: 25vw;
        height: 7vw;
        font-size: 3vw;
        border-radius: 4vw;
    }
    .SQBAll{border: none;}
    .SQBClea{display: none;}

    .SelectPos
    {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .SelectQTextJSLine{ width: 45vw;}


    .MoveArea
    {
        display: block;
        margin-top: 5vh;
        margin-left: auto;
    }
    .MoveChapArea
    {
        display: none;
    }
    .MoveChapArea1
    {
        display: none;
    }
    .MoveChapArea2
    {
        width: 80vw;
        max-width: 100vw;
        height: auto;
        display: flex;
        margin-top: 18.3vh;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        z-index: 1;
    }
    .MoveChapArea3
    {
        width: 80vw;
        max-width: 100vw;
        height: auto;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        background-color: rgb(255,255,255);
        z-index: 1;
    }
    .MoveChapBtn
    {
        width: 20vw;
        height: 5vh;
        justify-content: center;
    }
    .MoveSet
    {
        width: 99.6vw;
        max-width: 100vw;
        margin-left: auto;
    }

    .TextArea
    {
        width: 100vw;
        display: block;
        position: absolute;
        margin-top: 0vh;
        margin-left: auto;
        z-index: 2;
    }
    .MoveText
    {
        width: 90vw;
        max-width: 100vw;
        height: 15vh;
        margin-top: 3vh;
        font-size: 2.7vh;
    }
    .BurmaMoveText{font-size: 2.4vh;}

    
}